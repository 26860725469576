<template>
  <!--
    The gui to edit the videoDirectorConfig-recorders.
  -->
  <div class="videoDirectorConfigRecorderForm">
    <Portlet
      :title="`${ $t('videoRecorder') } ${ configId } ${ $t('configuration') }`"
      icon="video"
    >
      <template slot="buttons">
        <toggle-button
          v-model="disablePtz"
          :labels="{ checked: 'Enable PTZ', unchecked: 'Disable PTZ' }"
          :color="{ checked: 'gray', unchecked: 'green' }"
          :width="100"
          :height="30"
          :font-size="10"
          class="mb-2 mt-2 mr-2"
        />
        <div>
          <h5>
            <span
              v-if="status.cameraOperatorOperationStatus == 'active'"
              class="badge badge-danger mb-2 mt-2"
            >Camera in Racing mode - Camera movement disabled</span>
          </h5>
          <div v-if="updated">
            <h3>
              <span
                class="badge badge-danger mb-2"
              >Unsaved changes detected</span>
            </h3>
          </div>
        </div>
      </template>
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <template v-if="videoRecorderConfig">
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-7">
              <VideoDirectorConfigDefaultVisualiser
                ref="videoDirectorConfigDefaultVisualiser"
                :lane-config="videoRecorderConfig.laneConfig"
                :installation-id="installationId"
                :config-id="configId"
                :pt-head-position="status.currentPt"
                :display-all-axes="true"
                :edit-border="true"
                :edit-zoom="true"
                :display="['flowArea', 'zoomPoint', 'borderArea', 'trigger', 'currentPosition']"
                :zoom-map="zoomMap"
                @getConfig="getConfig()"
                @changePtz="changePtz"
              />

              <div class="crosshair mt-4">
                <VideoDirectorConfigMotionDetectionVisualiser
                  v-if="displayMotionDetectionArea"
                  :edit="false"
                  :flow-area="currentFlowArea"
                  @update="updateVisualiser"
                />
                <span class="horizontal" />
                <span class="vertical" />
                <LoadingPlaceholder v-if="imageLoading && videoRecorderConfig.previewCamera" />
                <img
                  v-else-if="image"
                  id="imageSelector"
                  :src="`data:image/png;base64,${ image.base64Data }`"
                  class="m-0 p-0 previewImage"
                  @click="moveToPosition"
                >
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-5">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    href="#areas"
                    data-toggle="tab"
                    aria-controls="areas"
                    aria-selected="false"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="code-branch"
                      style="vertical-align: middle;"
                    />Flows &amp; Triggers
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#laneCommonConfig"
                    data-toggle="tab"
                    aria-controls="laneCommonConfig"
                    aria-selected="false"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="draw-polygon"
                      style="vertical-align: middle;"
                    />Lane config
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#ptHead"
                    data-toggle="tab"
                    aria-controls="ptHead"
                    aria-selected="false"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="sliders-h"
                      style="vertical-align: middle;"
                    />PT Head
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div
                  id="areas"
                  class="tab-pane active"
                  role="tabpanel"
                  aria-labelledby="areas"
                >
                  <FlowConfiguration
                    :flow-areas="videoRecorderConfig.laneConfig.flowAreas"
                    :lane-config="videoRecorderConfig.laneConfig"
                    :sf-config="sfConfigData"
                    @update="updateVisualiser"
                    @changePtz="changePtz"
                    @toogleMotionDetectionArea="toogleMotionDetectionArea"
                    @currentFlow="setCurrentFlow"
                  />
                  <TriggerConfiguration
                    class="mt-2"
                    :triggers="videoRecorderConfig.laneConfig.triggers"
                    :lane-config="videoRecorderConfig.laneConfig"
                    @update="updateVisualiser"
                    @changePtz="changePtz"
                  />
                </div>
                <div
                  id="laneCommonConfig"
                  class="tab-pane"
                  role="tabpanel"
                  aria-labelledby="laneCommonConfig"
                >
                  <BorderConfiguration
                    :border-area="videoRecorderConfig.laneConfig.borderArea"
                    @update="updateVisualiser"
                    @changePtz="changePtz"
                  />

                  <ZoomConfiguration
                    class="mt-2"
                    :zoom-points="videoRecorderConfig.laneConfig.zoomPoints"
                    :pan="status.currentPt.pan"
                    :tilt="status.currentPt.tilt"
                    @update="updateVisualiser"
                    @changePtz="changePtz"
                    @zoomMapCalculated="zoomMapCalculated"
                  />
                </div>
                <div
                  id="ptHead"
                  class="tab-pane"
                  role="tabpanel"
                  aria-labelledby="ptHead"
                >
                  <PtHeadConfiguration
                    :configs="videoRecorderConfig.laneConfig.ptHeadConfigs"
                    @update="updateVisualiser"
                  />
                </div>
                <div v-if="status && status.currentZoom">
                  <span>Current Zoom: {{ status.currentZoom.toFixed(2) }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>

        <hr>
        <button
          v-if="updated"
          class="btn btn-primary float-right"
          @click="openModal()"
        >
          <font-awesome-icon
            class="mr-1"
            icon="upload"
          />
          <span>{{ $t('save') }}</span>
        </button>
        <div
          v-if="updated"
          class="red float-right mr-4"
        >
          <h3>
            <span class="badge badge-danger">Unsaved changes detected</span>
          </h3>
        </div>
        <router-link
          :to="{ path: `/installation/${ installationId }/tracker/config/videodirector` }"
          class="btn btn-secondary float-left"
        >
          <font-awesome-icon
            class="mr-2"
            icon="arrow-left"
            style="vertical-align: middle; color: #6f727d;"
          />
          {{ $t('back') }}
        </router-link>
        <div class="clearfix" />
      </template>
      <SweetModal
        ref="commentModal"
        blocking
        title="Add Comment (optional)"
        class="overflowHidden"
      >
        <div class="form-group">
          <label>{{ $t('comment') }}</label>
          <textarea
            v-model="comment"
            class="form-control"
            placeholder="please select a comment"
            type="text"
            rows="5"
          />
        </div>
        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.commentModal.close()"
        >
          <font-awesome-icon
            class="mr-2 gray"
            icon="times"
          />
          <span>{{ $t('cancel') }}</span>
        </button>
        <button
          slot="button"
          class="btn btn-primary float-right mb-3"
          @click="setConfig()"
        >
          <font-awesome-icon
            class="mr-1"
            icon="upload"
          />
          <span>{{ $t('save') }}</span>
        </button>
        <div class="clearfix" />
      </SweetModal>
    </Portlet>
  </div>
</template>

<script>
// import mixins
import { errorMixin } from "@/mixins/errorMixin.js";
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "VideoRecorderConfiguration",
  components: {
    VideoDirectorConfigDefaultVisualiser: () =>
      import(
        "@/components/Config/VideoDirector/Visualiser/VideoDirectorConfigDefaultVisualiser.vue"
      ),
    VideoDirectorConfigMotionDetectionVisualiser: () =>
      import(
        "@/components/Config/VideoDirector/Visualiser/VideoDirectorConfigMotionDetectionVisualiser.vue"
      ),
    TriggerConfiguration: () => import("./TriggerConfiguration.vue"),
    FlowConfiguration: () => import("./FlowConfiguration.vue"),
    BorderConfiguration: () => import("./BorderConfiguration.vue"),
    ZoomConfiguration: () => import("./ZoomConfiguration.vue"),
    PtHeadConfiguration: () => import("./PtHeadConfiguration.vue"),
    SweetModal
  },
  mixins: [errorMixin],
  props: {
    installationId: {
      type: String,
      required: true
    },
    configId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      comment: null,
      loading: true,
      videoRecorderConfig: null,
      videoRecorders: null,
      activeVideoRecorderId: null,
      originalVideoRecorderConfig: null,
      statusCancelSource: null,
      statusInterval: null,
      status: {
        currentPt: {
          pan: 0,
          tilt: 0
        }
      },
      image: null,
      imageIntervalVar: null,
      imageLoading: true,
      getImageCancelSource: null,
      displayMotionDetectionArea: false,
      changePtzCancelSource: null,
      currentFlowArea: null,
      zoomMap: null,
      disablePtz: false,
      sfLoading: false,
      sfConfigData: null
    };
  },
  computed: {
    updated () {
      return (
        JSON.stringify(this.videoRecorderConfig) !==
        JSON.stringify(this.originalVideoRecorderConfig)
      );
    }
  },
  created () {
    this.createInterval();
    this.createStatusInterval();
  },
  async mounted () {
    await this.getConfig();
    this.getVideoRecorders();
  },
  beforeDestroy () {
    this.cancelStatusInterval();
    this.cancelPtzRequest();
    this.cancelImageRequest();
    this.cancelInterval();
    this.cancelStatusRequest();
  },
  methods: {
    openModal () {
      this.comment = null;
      this.$refs.commentModal.open();
    },
    setCurrentFlow (flowArea) {
      this.currentFlowArea = flowArea;
    },
    toogleMotionDetectionArea (visible) {
      this.displayMotionDetectionArea = visible;
    },
    cancelInterval () {
      this.cancelPtzRequest();
      this.cancelImageRequest();
      window.clearInterval(this.imageIntervalVar);
      this.imageIntervalVar = null;
    },
    cancelStatusInterval () {
      this.cancelStatusRequest();
      window.clearInterval(this.statusInterval);
      this.statusInterval = null;
    },
    createInterval () {
      this.imageIntervalVar = window.setInterval(() => {
        this.getImage();
      }, 500);
    },
    createStatusInterval () {
      this.statusInterval = window.setInterval(() => {
        this.getStatus();
      }, 500);
    },
    cancelPtzRequest () {
      if (this.changePtzCancelSource) {
        this.changePtzCancelSource.cancel();
      }
    },
    cancelImageRequest () {
      if (this.getImageCancelSource) {
        this.getImageCancelSource.cancel();
      }
    },
    cancelStatusRequest () {
      if (this.statusCancelSource) {
        this.statusCancelSource.cancel();
      }
    },
    // HELPER METHODS END
    // API CALLS START
    getStatus () {
      if (this.videoRecorderConfig == null) {
        return;
      }
      if (this.videoRecorderConfig.cameraId == null) {
        return;
      }
      this.cancelStatusRequest();
      this.statusCancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.statusCancelSource.token };
      let url = `/VideoDirector/GetStatus?installationId=${this.installationId}&videoRecorderId=${this.acticeVideoRecorderId}`;
      if (this.laneNumber) {
        url += `&laneNumber=${this.laneNumber}`;
      }
      if (this.acticeVideoRecorderId)
      {
        this.axios
        .get(url, requestConfig)
        .then(response => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (typeof response.data != "object") {
            this.status = {};
            return;
          }
          this.status = response.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.cancelStatusInterval();
        }); 
      }
    },
    getImage () {
      if (!this.installationId) {
        return;
      }
      if (!this.videoRecorderConfig) {
        return;
      }
      if (!this.videoRecorderConfig.id) {
        return;
      }
      // if (this.videoRecorderConfig.previewCamera == false) {
      //   return;
      // }
      this.cancelImageRequest();
      this.getImageCancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.getImageCancelSource.token };
      let url = `/VideoDirector/GetImage?installationId=${this.installationId}&id=${this.acticeVideoRecorderId}`;
      if (this.laneNumber) {
        url += `&laneNumber=${this.laneNumber}`;
      }
      this.axios
        .get(url, requestConfig)
        .then(response => {
          if (this.axios.isCancel()) {
            return;
          }
          if (response == null || response.status == null) {
            return;
          }
          if (response.status == 200) {
            this.getImageCancelSource = null;
            this.image = response.data;
          } else {
            this.cancelInterval();
          }
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.cancelInterval();
        })
        .finally(() => {
          this.imageLoading = false;
        });
    },
    async changePtz (position) {
      if (this.status.cameraOperatorOperationStatus != "active" && !this.disablePtz) {
        this.cancelPtzRequest();
        this.changePtzCancelSource = this.axios.CancelToken.source();
        let requestConfig = { cancelToken: this.changePtzCancelSource.token };
        let url = `/VideoDirector/ChangePtz?installationId=${this.installationId}&recorderId=${this.acticeVideoRecorderId}`;
        if (this.laneNumber) {
          url += `&laneNumber=${this.laneNumber}`;
        }
        this.axios
          .post(url, position, requestConfig)
          .then(response => {
            if (response) {
              this.getImage();
              this.changePtzCancelSource = null;
            }
          })
          .catch(() => {
            if (this.axios.isCancel()) {
              return;
            }
          });
      }
    },
    updateVisualiser () {
      this.$refs.videoDirectorConfigDefaultVisualiser.update();
    },
    async getConfig () {
      let url = `/Config/GetVideoRecorderConfig?installationId=${this.installationId}&videoRecorderId=${this.configId}`;
      if (this.laneNumber) {
        url += `&laneNumber=${this.laneNumber}`;
      }
      return await this.axios
        .get(url)
        .then(async (response) => {
          if (response && response.data) {
            let videoRecorderConfig = response.data;

            if (!videoRecorderConfig.laneConfig.triggers) {
              videoRecorderConfig.laneConfig.triggers = [];
            }
            if (!videoRecorderConfig.laneConfig.borderArea) {
              videoRecorderConfig.laneConfig.borderArea = [];
            }
            if (!videoRecorderConfig.laneConfig.zoomPoints) {
              videoRecorderConfig.laneConfig.zoomPoints = [];
            }
            await this.getSfConfig(response.data, videoRecorderConfig);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getVideoRecorders () {
      let url = `VideoDirector/GetAll?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }       
          this.videoRecorders = response.data;
          if (this.videoRecorderConfig) {
            this.acticeVideoRecorderId = this.videoRecorders.find(p => p.videoIndexId == this.videoRecorderConfig.orderId).id;
            this.getImage()
          } 
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getSfConfig (org, videoRecorderConfig) {
      this.sfLoading = true;
      let url = `/Config/GetStartFinishConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`
      }
      await this.axios.get(url)
        .then((response) => {
          this.sfConfigData = response.data;
          if (videoRecorderConfig.laneConfig.flowAreas && this.sfConfigData && this.sfConfigData.openingHours && this.sfConfigData.openingHours.length > 0) {
            let opening = this.sfConfigData.openingHours[0].openTime;
            let closing = this.sfConfigData.openingHours[0].closeTime;
              for(var item of videoRecorderConfig.laneConfig.flowAreas) {
                if(!item.flowTimeStart) {
                  item.flowTimeStart = opening.substring(0, opening.lastIndexOf(':'));
                }

                if(!item.flowTimeEnd) {
                  item.flowTimeEnd = closing.substring(0, closing.lastIndexOf(':'));
                }
              }
            }
            
            this.videoRecorderConfig = videoRecorderConfig;
            this.originalVideoRecorderConfig = JSON.parse(
              JSON.stringify(org)
            );
        })
        .finally(() => {
          this.sfLoading = false;
        })
        .catch(() => {
          this.sfLoading = false;
        });
    },
    setConfig () {
      this.$refs.commentModal.close();
      let url = `/Config/UpdateVideoRecorderConfig?installationId=${this.installationId}&videoRecorderId=${this.configId}`;
      if (this.laneNumber) {
        url += `&laneNumber=${this.laneNumber}`;
      }
      
      let params = {
        videoRecorderConfig: this.videoRecorderConfig,
        comment: this.comment
      }
      this.axios
        .post(url, params )
        .then(() => {
          this.originalVideoRecorderConfig = JSON.parse(
            JSON.stringify(this.videoRecorderConfig)
          );
        })
        .catch(error => {
          this.$snotify.error(
            this.$t("videoDirectorConfigRecorderFormComponent.configNotSaved")
          );
          this.error_clear();
          this.error_validate(error);
        });
    },
    moveToPosition (event) {
      if (this.status.cameraOperatorOperationStatus != "active") {
        let image = document.getElementById("imageSelector");
        let widthPercentage = (event.offsetX / image.width) * 100;
        let heightPercentage = (event.offsetY / image.height) * 100;
        let moveToPositionDto = {
          widthPercentage: widthPercentage,
          heightPercentage: heightPercentage
        };
        let url = `/VideoDirector/MoveToPosition?installationId=${this.installationId}&videoRecorderId=${this.acticeVideoRecorderId}`;
        if (this.laneNumber) {
          url += `&laneNumber=${this.laneNumber}`;
        }
        this.axios
          .post(url, moveToPositionDto)
          .then(() => {
            this.getConfig();
            this.error_clear();
          })
          .catch(error => {
            this.$snotify.error(
              this.$t(
                "videoDirectorConfigRecorderFormComponent.movedCameraError"
              )
            );
            this.error_clear();
            this.error_validate(error);
          });
      }
    },
    // API CALLS END
    zoomMapCalculated (zoomMap) {
      this.zoomMap = zoomMap; //zoomMap can be also null to hide it
    }
  }
};
</script>

<style scoped>
.boolLabel {
  position: relative;
  top: -1.5rem;
}
.previewImage {
  width: 100%;
  height: auto;
}
.crosshair {
  position: relative;
}
.videoDirectorConfigMotionDetectionVisualiser {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
</style>
